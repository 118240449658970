import React, { useState, useEffect } from "react"
import { ErrorBoundary } from "../../Components/ErrorBoundary"
import { ErrorNotice } from "../../Components/Common/ErrorNotice"
import "../../../node_modules/react-datetime/css/react-datetime.css"
import Datetime from "react-datetime"
import moment from "moment"
import Checkbox from "@material-ui/core/Checkbox"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"

import { callHebridesApi } from "../../Helpers/fetch"
import { ApiResponse } from "../../Components/APIResponse"
import { GetTaskQueue } from "../../Components/Tasks/TaskQueue"
import { Layout } from "../../Components/PageLayout"
import { StyledLinearProgress } from "../../Components/StyledLoader"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export const Upgrade = () => {
  const [deployVars, setDeployVars] = useState({
    environment: '',
    deploymentTime: moment().format("MM/DD/YYYY HH:mm A"),
    tenants: [],
    version: '',
  })
  const [dashboard, setDashboard] = useState(null)
  const [releases, setReleases] = useState(null)
  const [hasLoadError, setHasLoadError] = useState(false)
  const [submissionResult, setSubmissionResult] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const isFormReady = !hasLoadError && dashboard !== null && releases !== null
  const isFormLoading = !hasLoadError && (dashboard === null || releases === null)
  const isFormError = hasLoadError

  // Specifies whether all the selected tenants have the "Production Pre-Release" tag to let them
  // be deployed with pre-release SOE versions even on the Production environment
  const [allTenantsAllowProductionPreRelease, setAllTenantsAllowProductionPreRelease] = useState(false)

  useEffect(() => {
    callHebridesApi("GET", "/v2/service/deploy/dashboard", "")
      .then(response => setDashboard(response.data))
      .catch(error => {
        console.error(error)
        setHasLoadError(true)
      })

    callHebridesApi("GET", "/v2/service/deploy/releases", "")
      .then(response => setReleases(response.data.items))
      .catch(error => {
        console.error(error)
        setHasLoadError(true)
      })
  }, [])

  const handleEnvironmentChange = ev => {
    // Changing the environment will invalidate the tenant selection. Confirm that the user wants to change the
    // environment if tenants were already selected, in case they do this in error and lose a lot of selections
    // This is an event handler, so doing a window.confirm is fine (and avoids dealing with messy React modals)
    if (deployVars.tenants.length > 0) {
      if (!window.confirm(
        "Selecting a different environment will clear the current customer selection. Is this ok?"
      )) {
        return;
      }
    }

    setDeployVars({
      ...deployVars,
      environment: ev.target.value,
      tenants: []
    });
  }

  const handleVersionChange = ev => {
    setDeployVars({
      ...deployVars,
      version: ev.target.value
    });
  }

  const handleTenantsChange = (evt, selectedTenants) => {
    setDeployVars({
      ...deployVars,
      tenants: selectedTenants,
    })

    setAllTenantsAllowProductionPreRelease(selectedTenants.every(t => t.allowProductionPreRelease))
  }

  const selectAllTenants = ev => {
    handleTenantsChange(null, getTenantOptions())
  }

  const clearAllTenants = ev => {
    handleTenantsChange(null, [])
  }

  const handleDateTime = (evt) => {
    const value = moment(evt._d).format("MM/DD/YYYY HH:mm A")
    setDeployVars({
      ...deployVars,
      deploymentTime: value,
    })
  }

  const noWeekends = (current) => {
    return (
      current.day() !== 0 &&
      current.day() !== 6 &&
      current.isAfter(Datetime.moment().subtract(1, "day"))
    )
  }

  const submitDeployment = (evt) => {
    let tenantsToDeploy = []
    deployVars.tenants.forEach(tenant => {
      tenantsToDeploy.push({
        ReleaseId: deployVars.version,
        Environment: deployVars.environment,
        Client: tenant.name,
        QueueTime: moment(
          deployVars.deploymentTime,
          "MM/DD/YYYY HH:mm A"
        ).toISOString(),
      })
    })

    setIsSubmitting(true)
    setSubmissionResult(null)

    callHebridesApi("POST", "/v2/infra/upgrade/create", tenantsToDeploy)
      .then(response => setSubmissionResult({ state: 'OK', response: response }))
      .catch(error => {
        if (error.response) {
          setSubmissionResult({ state: 'ERROR', response: error.response })
        } else {
          console.error(error)
          setSubmissionResult({
            state: 'ERROR',
            response: { status: 500, data: 'Check the browser console for more details.' }
          })
        }
      })
      .finally(() => setIsSubmitting(false))
    evt.preventDefault()
  }

  // Gets a collection of <option> elements corresponding to the SOE versions
  // that can be deployed for the selected environment and tenants
  function getDeployableSoeVersionOptions() {
    if (!releases) {
      return null
    }

    const isPreReleaseAllowed = deployVars.environment != "Prod" || allTenantsAllowProductionPreRelease
    return (
      // Any version including a hyphen is a pre-release - this includes "-pre-release" and "-main-dev" for example
      releases
        .filter(release => isPreReleaseAllowed || !release.version.includes("-"))
        .map(release =>
          <option key={release.id}>{release.version}</option>
        )
    )
  }

  function getTenantOptions() {
    if (!dashboard) {
      return []
    }

    const selectedEnvironment = dashboard.environments.find(env => env.name === deployVars.environment)
    if (!selectedEnvironment) {
      return []
    }

    const soeProjectId = dashboard.projects.length == 1 ? dashboard.projects[0].id : null;
    if (!soeProjectId) {
      console.error("Couldn't find Stack Overflow Enterprise's project ID.")
      return []
    }

    return dashboard.tenants
      .filter(tenant => tenant.projectEnvironments.hasOwnProperty(soeProjectId) && tenant.projectEnvironments[soeProjectId].includes(selectedEnvironment.id))
      .map(tenant => {
        const allowProductionPreRelease = (tenant.tenantTags || [])
          .includes('Environment Type/Prod-pre-release')
        return { key: tenant.id, name: tenant.name, allowProductionPreRelease: allowProductionPreRelease }
      })
  }

  return (
    <>
      <Layout title="Schedule Deployment to SOE Instances">
        { isFormError && <ErrorNotice /> }
        { isFormLoading && <StyledLinearProgress /> }
        { isFormReady && (
          <form>
            <div className="d-flex">
              <div className="flex--item6">
                <label className="control-label">Environment</label>
                <div className="form-group">
                  <select
                    className="form-control"
                    id="environment"
                    value={deployVars.environment}
                    onChange={handleEnvironmentChange}
                  >
                    <option value="_blank"></option>
                    { dashboard && dashboard.environments
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((entry) => {
                          return <option key={entry.id}>{entry.name}</option>
                        })}
                  </select>
                </div>
              </div>
              <div className="flex--item6">
                <label className="control-label">SOE Version to Deploy</label>
                <div className="form-group">
                  <select
                    className="form-control"
                    id="version"
                    value={deployVars.version}
                    onChange={handleVersionChange}
                  >
                    <option value="_blank"></option>
                    {getDeployableSoeVersionOptions()}
                  </select>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="flex--item6">
                <label className="control-label">Deployment Time</label>
                <div className="form-group">
                  <Datetime
                    className="datepicker"
                    id="deployTime"
                    defaultValue={new Date()}
                    dateFormat={"MM/DD/YYYY"}
                    inputProps={{ placeholder: "Select Date and Time" }}
                    isValidDate={noWeekends}
                    timeConstraints={{ minutes: { step: 5 } }}
                    onChange={handleDateTime}
                    renderInput={(props, openCalendar) => (
                      <>
                        <div className="datepicker input-group">
                          <input type="text" {...props} />
                          <span
                            className="input-group-addon"
                            onClick={openCalendar}
                          >
                            <i className="fa fa-calendar" />
                          </span>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="flex--item6">
                <label className="control-label">Customer(s)</label>
                <div className="form-group d-flex ai-start gs4 gsx">
                  <Autocomplete
                    multiple
                    id="tenants"
                    className="upgradesel flex--item fl-grow1"
                    value={deployVars.tenants}
                    onChange={handleTenantsChange}
                    options={getTenantOptions()}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.key === value.key}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Customers" />
                    )}
                  />
                  <button
                    className="s-btn s-btn__muted s-btn__outlined flex--item fl-none"
                    type="button"
                    onClick={selectAllTenants}
                  >
                    Select All
                  </button>
                  <button
                    className="s-btn s-btn__muted s-btn__outlined flex--item fl-none"
                    type="button"
                    onClick={clearAllTenants}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex">
              <div className="flex--item3">
                <button
                  className={`s-btn s-btn__primary${isSubmitting ? ' is-loading' : ''}`}
                  id="submit"
                  type="button"
                  disabled={isSubmitting}
                  onClick={submitDeployment}
                >
                  Submit
                </button>
              </div>
              <div className="flex--item6">
                {submissionResult && (submissionResult.state === 'OK' || submissionResult.state == 'ERROR') &&
                  <ApiResponse response={submissionResult.response} />
                }
              </div>
            </div>
          </form>
        )}
      </Layout>
      <Layout title="Upgrade Queue">
        <ErrorBoundary>
          <GetTaskQueue api="/v2/infra/tasks/state?tasktype=deployment" />
        </ErrorBoundary>
      </Layout>
    </>
  )
}
